import { Divider } from 'antd'
import { AxiosError } from 'axios'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { ICandidateModel, ILinkedInWorkingExperienceModel, ILinkedinEducationModel } from 'src/interfaces'
import { BreadcrumbService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { CandidateDocuments } from './documents'
import { CandidateEducation } from './education'
import { CandidateExperience } from './experience'
import { CandidateGeneral } from './general'
import { CandidateNotifications } from './notifications'
import { CandidateStats } from './stats'
import { CandidateVideos } from './videos'

export const CandidateDashboard: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()
  const [candidate, setCandidate] = useState<ICandidateModel>()
  const [workingExperience, setWorkingExperience] = useState<ILinkedInWorkingExperienceModel[]>()
  const [education, setEducation] = useState<ILinkedinEducationModel[]>()
  const [loadingExperiences, setLoadingExperiences] = useState(false)
  const [loadingEducation, setLoadingEducation] = useState(false)

  const refetchExperiences = useCallback(async () => {
    try {
      setLoadingExperiences(true)
      const { data } = await CandidateApi.getExperience(id)
      setWorkingExperience(data)
    } catch (error) {
      NotifyUtils.handleAxiosError(error as AxiosError)
    } finally {
      setLoadingExperiences(false)
    }
  }, [id])

  const refetchEducation = useCallback(async () => {
    try {
      setLoadingEducation(true)
      const { data } = await CandidateApi.getEducation(id)
      setEducation(data)
    } catch (error) {
      NotifyUtils.handleAxiosError(error as AxiosError)
    } finally {
      setLoadingEducation(false)
    }
  }, [id])

  useEffect(() => {
    if (!id) {
      return
    }

    from(CandidateApi.show(id))
      .pipe(takeUntil(unsubscribe$))
      .subscribe({
        next: ({ data }) => setCandidate(data),
        error: NotifyUtils.handleAxiosError
      })

    refetchExperiences()
    refetchEducation()
  }, [id, refetchEducation, refetchExperiences, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.CANDIDATE,
        label: 'Candidates'
      },
      {
        route: [EPaths.CANDIDATE_DASHBOARD, { id }],
        label: 'Dashboard'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  return (
    <section className="fx fx-column fx-extend gap-1">
      <CandidateGeneral
        candidate={candidate}
        onDisabled={(val) => {
          // setLoadingExperiences(val)
          // setLoadingEducation(val)
          if (!val) {
            refetchExperiences()
            refetchEducation()
          }
        }}
      />

      <Divider className="my-2" style={{ borderColor: 'black' }}/>

      <CandidateStats candidate={candidate}/>

      <Divider className="my-2" style={{ borderColor: 'black' }}/>

      <CandidateVideos candidate={candidate}/>

      <Divider className="my-2" style={{ borderColor: 'black' }}/>

      <CandidateDocuments candidate={candidate}/>

      <Divider className="my-2" style={{ borderColor: 'black' }}/>

      <CandidateExperience
        workingExperience={workingExperience}
        linkedinUrl={candidate?.linkedInUrl}
        candidateId={candidate?.id}
        loading={loadingExperiences}
        onOk={refetchExperiences}
      />
      <br/>
      <CandidateEducation
        education={education}
        candidateId={candidate?.id}
        loading={loadingEducation}
        onOk={refetchEducation}
      />

      <Divider className="my-2" style={{ borderColor: 'black' }}/>

      <CandidateNotifications candidate={candidate}/>
    </section>
  )
}
