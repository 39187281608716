import axios from 'axios'
import { ICandidateModel, IFileUploadModel, ILinkedInWorkingExperienceModel, ILinkedinEducationModel, INotificationModel, IPaginationQuery, IQuizResult, IUserStatsModel, IVideoModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'
import { AxiosUtils, Helpers } from 'src/utils'

export class CandidateApi {
  static readonly _prefix = '/candidates'

  static parseParams(params: IPaginationQuery = {}) {
    return {
      ...params,
      hasLinkedinUrl: params.hasLinkedinUrl ? true : undefined,
      isActive: !Number(params.isActive)
        ? undefined
        : Number(params.isActive) === 1
    }
  }

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<ICandidateModel> {
    return axios.get(`${CandidateApi._prefix}`, { params })
  }

  static download(params: IPaginationQuery = {}): Promise<void> {
    return axios.get(`${CandidateApi._prefix}/download`, {
      params,
      responseType: 'arraybuffer'
    }).then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const url = URL.createObjectURL(blob)
      Helpers.download({
        href: url,
        filename: AxiosUtils.exposeFileNameFromContentDisposition(response.headers, 'candidates.csv')
      })

      URL.revokeObjectURL(url)
    })
  }

  static show(id: number | string): TAxiosResponseData<ICandidateModel> {
    return axios.get(`${CandidateApi._prefix}/${id}`)
  }

  static getQuizResult(id: number | string, params?: {
    pointOfView: 'firstPersonSingular' | 'thirdPersonSingular'
  }): TAxiosResponseData<IQuizResult> {
    return axios.get(`${CandidateApi._prefix}/${id}/quiz-result`, { params })
  }

  static stats(id: number | string): TAxiosResponseData<IUserStatsModel> {
    return axios.get(`${CandidateApi._prefix}/${id}/stats`)
  }

  static update(id: number | string, payload: {
    linkedInUrl?: string
  }): TAxiosResponseData<ICandidateModel> {
    return axios.put(`${CandidateApi._prefix}/${id}`, payload)
  }

  static destroy(id: number | string): TAxiosResponseData<void> {
    return axios.delete(`${CandidateApi._prefix}/${id}`)
  }

  static toggleIsActive(id: number | string): TAxiosResponseData<void> {
    return axios.patch(`${CandidateApi._prefix}/${id}/active`)
  }

  static sendNotification(id: number | string, payload: {
    title?: string
    body?: string
    action?: string
    imageUrl?: string
  }): TAxiosResponseData<void> {
    return axios.post(`${CandidateApi._prefix}/${id}/notifications`, payload)
  }

  static videos(id: number | string, params: IPaginationQuery = {}): TAxiosResponsePagination<IVideoModel> {
    return axios.get(`${CandidateApi._prefix}/${id}/videos`, { params })
  }

  static documents(id: number | string, params: IPaginationQuery = {}): TAxiosResponsePagination<IFileUploadModel> {
    return axios.get(`${CandidateApi._prefix}/${id}/documents`, { params })
  }

  static notifications(id: number | string, params: IPaginationQuery = {}): TAxiosResponsePagination<INotificationModel> {
    return axios.get(`${CandidateApi._prefix}/${id}/notifications`, { params })
  }

  static scrapeLinkedin(id: number | string): TAxiosResponsePagination<void> {
    return axios.put(`${CandidateApi._prefix}/${id}/linkedin`)
  }

  static getExperience(id: number | string): TAxiosResponseData<ILinkedInWorkingExperienceModel[]> {
    return axios.get(`${CandidateApi._prefix}/${id}/experiences`)
    // .then((result) => {
    //   result.data = LinkedInUtils.checkValidData(result.data)
    //   return result
    // })
  }

  /**
   * Query by candidate ids
   */
  static getExperiencesByIds(params: { ids: number[] }) {
    return axios.get<ILinkedInWorkingExperienceModel[]>(`${CandidateApi._prefix}/experiences`, { params }).then(
      ({ data }) => data.reduce<{ [id: string]: ILinkedInWorkingExperienceModel[] }>((acc, cur) => ({
        ...acc,
        [cur.userId]: [
          ...(acc[cur.userId] || []),
          cur
        ]
      }), {})
    )
  }

  static addExperiences(id: number | string, payload: {
    linkedinUrl: string
    experiences: Partial<ILinkedInWorkingExperienceModel>[]
  }): TAxiosResponseData<ILinkedInWorkingExperienceModel[]> {
    return axios.post(`${CandidateApi._prefix}/${id}/experiences`, payload)
  }

  static getEducation(id: number | string): TAxiosResponseData<ILinkedinEducationModel[]> {
    return axios.get(`${CandidateApi._prefix}/${id}/education`)
  }

  static addEducation(id: number | string, payload: {
    education: Partial<ILinkedinEducationModel>[]
  }): TAxiosResponseData<ILinkedinEducationModel[]> {
    return axios.post(`${CandidateApi._prefix}/${id}/education`, payload)
  }
}
