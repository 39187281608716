import { Button, Popconfirm, Space, TableProps } from 'antd'
import { ICampaignModel, ICompanyUserModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { TimeZoneService } from 'src/services'
import { CampaignUtils, UserUtils } from 'src/utils'

export const renderColumns = (props: {
  loading?: boolean
  onApprove?: (record: ICompanyUserModel) => any
} = {}): Required<TableProps<ICompanyUserModel & { campaign?: ICampaignModel }>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'user',
    key: 'user',
    render: (val?: ICompanyUserModel['user']) => UserUtils.getFullName(val)
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    render: (val?: ICompanyUserModel['company']) => val?.name
  },
  {
    title: 'Logo',
    dataIndex: 'logo',
    key: 'logo',
    render: (_, record: ICompanyUserModel & { campaign?: ICampaignModel }) => {
      const logoSrc = CampaignUtils.getLogoUrl(record.campaign) || record.company?.logo?.url
      return !logoSrc
        ? null
        : <img src={logoSrc} alt="icon" style={{ height: 50 }}/>
    }
  },
  {
    title: 'Company Website',
    dataIndex: 'companyWebsiteUrl',
    key: 'companyWebsiteUrl',
    render: (_, record: ICompanyUserModel & { campaign?: ICampaignModel }) => {
      return record.company?.url
        ? (
          <span
            title={record.company?.url}
            className="twoline-text"
            style={{ maxWidth: '300px' }}
          >
            {record.company?.url}
          </span>
        )
        : null
    }
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: ICompanyUserModel['createdAt']) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: ICompanyUserModel['updatedAt']) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record: ICompanyUserModel & { campaign?: ICampaignModel }) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        <Button
          type="link"
          size="small"
          target="_blank"
          href={generate([ERoutes.CAMPAIGN_DETAIL, { id: record.campaign?.id }])}
        >
          Detail
        </Button>

        {!record.isVerified && (
          <Popconfirm
            disabled={props.loading}
            title="Are you sure to verify this hiring manager?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.onApprove?.(record)}
          >
            <Button
              type="link"
              size="small"
              target="_blank"
              href={generate([ERoutes.CAMPAIGN_DETAIL, { id: record.campaign?.id }])}
            >
              Approve
            </Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
