import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Result, Select, SelectProps, Timeline, Tooltip } from 'antd'
import { AxiosError } from 'axios'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { FieldData } from 'rc-field-form/lib/interface'
import { FC, useCallback, useEffect, useState } from 'react'
import { CandidateApi } from 'src/api'
import { Editor, ModalFullscreen, RangePicker } from 'src/components'
import { EEmploymentType, ELocationType } from 'src/constants/enum'
import { ILinkedInWorkingExperienceModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { v4 } from 'uuid'

interface ICandidateExperienceProps {
  candidateId?: number | string
  workingExperience?: ILinkedInWorkingExperienceModel[]
  linkedinUrl?: string
  onOk?: () => void
  loading?: boolean
}

const defaultExperience = () => ({
  key: v4(),
  title: '',
  company: '',
  location: ''
})

const EMPLOYMENT_TYPE_OPTIONS: SelectProps['options'] = Object.values(EEmploymentType).map((value) => ({
  value,
  label: capitalize(value.replace(/_/g, ' '))
}))

const LOCATION_TYPE_OPTIONS: SelectProps['options'] = Object.values(ELocationType).map((value) => ({
  value,
  label: capitalize(value.replace(/_/g, ' '))
}))

const SKILL_OPTIONS: SelectProps['options'] = [
  { value: 'Business Strategy', label: 'Business Strategy' },
  { value: 'Business Development', label: 'Business Development' },
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Web Content Writing', label: 'Web Content Writing' },
  { value: 'Marketing Analysis', label: 'Marketing Analysis' }
  // ... more here
]

export const CandidateExperience: FC<ICandidateExperienceProps> = ({ loading: parentLoading, onOk, candidateId, workingExperience = [], linkedinUrl }) => {
  const [openModalExperience, setOpenModalExperience] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)

  const [_linkedinUrl, setLinkedinUrl] = useState(linkedinUrl || '')
  useEffect(() => {
    setLinkedinUrl(linkedinUrl || '')
  }, [linkedinUrl, setLinkedinUrl])

  const [newWorkingExperiences, setNewWorkingExperiences] = useState<(Partial<ILinkedInWorkingExperienceModel> & { key?: string })[]>([defaultExperience()])

  useEffect(() => {
    if (workingExperience?.length) {
      setNewWorkingExperiences(
        workingExperience.map(exp => ({
          ...exp,
          key: v4()
        }))
      )

      return () => {
        setNewWorkingExperiences([defaultExperience()])
      }
    }
  }, [workingExperience])

  const handleDeleteExperience = (index: number) => {
    const experiences = [...newWorkingExperiences]
    experiences.splice(index, 1)
    setNewWorkingExperiences(experiences)
  }

  const handleOnFieldsChange = (allFields: FieldData[], index: number) => {
    const experiences = [...newWorkingExperiences]
    experiences[index] = {
      ...experiences[index],
      ...allFields.reduce((acc, { name, value }) => {
        if ((name as string[])[0] === 'duration') {
          const [start, end] = Array.isArray(value)
            ? value
            : []
          acc.startDate = start?.toDate()
          acc.endDate = end?.toDate()
          return acc
        }

        acc[(name as (keyof ILinkedInWorkingExperienceModel)[])[0]] = value
        return acc
      }, {} as Partial<ILinkedInWorkingExperienceModel>)
    }

    setNewWorkingExperiences(experiences)
  }

  const handleSubmit = useCallback(async () => {
    try {
      if (!candidateId) return
      setLoadingModal(true)

      await CandidateApi.addExperiences(candidateId, {
        linkedinUrl: _linkedinUrl,
        experiences: newWorkingExperiences.map(exp => ({
          title: exp.title,
          company: exp.company,
          location: exp.location,
          startDate: exp.startDate,
          endDate: exp.endDate ?? undefined,
          locationType: exp.locationType ?? undefined,
          employmentType: exp.employmentType ?? undefined,
          description: exp.description ?? undefined,
          skills: exp.skills ?? [],
          industry: exp.industry ?? undefined
        }))
      })

      onOk?.()
      setOpenModalExperience(false)
      NotifyUtils.success({ message: 'Add experiences successfully' })
    } catch (error) {
      NotifyUtils.handleAxiosError(error as AxiosError)
    } finally {
      setLoadingModal(false)
    }
  }, [setLoadingModal, candidateId, _linkedinUrl, newWorkingExperiences, onOk, setOpenModalExperience])

  return (
    <div>
      <Card
        title="Experience"
        bordered={false}
        loading={parentLoading}
        extra={Boolean(workingExperience.length) && (
          <Tooltip title="Modify experiences">
            <Button
              type="link"
              disabled={parentLoading}
              onClick={() => setOpenModalExperience(true)}
            >
              Modify
            </Button>
          </Tooltip>
        )}
      >
        {workingExperience.length
          ? (
            <Timeline mode="left">
              {workingExperience.map((experience, index) => (
                <Timeline.Item key={index} label={experience.workDuration || ' '}>
                  <div style={{ fontWeight: 'bold' }}>{experience.title}</div>
                  <div>{[experience.company, experience.location].filter(Boolean).join(' - ')}</div>
                </Timeline.Item>
              ))}
            </Timeline>
          )
          : (
            <Result
              title="There is no experience data"
              extra={(
                <Button type="primary" onClick={() => setOpenModalExperience(true)}>
                  Add experience
                </Button>
              )}
            />
          )}
      </Card>

      <ModalFullscreen
        title="Add new experience"
        centered
        open={openModalExperience}
        onOk={handleSubmit}
        onCancel={() => setOpenModalExperience(false)}
        confirmLoading={loadingModal}
      >
        <div style={{ position: 'absolute', bottom: 78, right: 24, zIndex: 2 }}>
          <Tooltip title="Modify experience">
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined/>}
              onClick={() => {
                setNewWorkingExperiences([...newWorkingExperiences, defaultExperience()])
              }}
            />
          </Tooltip>
        </div>

        <Card style={{ marginBottom: 10 }}>
          <Form
            labelCol={{
              sm: 6,
              md: 5,
              lg: 4
            }}
            name="basic"
            autoComplete="off"
            onFieldsChange={([{ value }]) => setLinkedinUrl(value)}
            initialValues={{ linkedinUrl: _linkedinUrl }}
          >
            <Form.Item
              label="Linkedin"
              name="linkedinUrl"
              rules={[{ required: true, message: 'Please input linkedin url!' }]}
            >
              <Input/>
            </Form.Item>
          </Form>
        </Card>

        {newWorkingExperiences.map((experience, index) => (
          <Card
            key={`card.${experience.key}`}
            style={{ marginBottom: 10, position: 'relative' }}
          >
            {newWorkingExperiences.length !== 1 && (
              <Button
                style={{ position: 'absolute', right: 0, top: 0 }}
                icon={<CloseOutlined/>}
                type="text"
                onClick={() => handleDeleteExperience(index)}
              />
            )}

            <Form
              key={index}
              labelCol={{
                sm: 6,
                md: 5,
                lg: 4
              }}
              name="basic"
              autoComplete="off"
              onFieldsChange={(_, allFields) => handleOnFieldsChange(allFields, index)}
              initialValues={{
                title: experience.title,
                company: experience.company,
                location: experience.location,
                duration: [
                  experience.startDate ? moment(experience.startDate) : null,
                  experience.endDate ? moment(experience.endDate) : null
                ],
                employmentType: experience.employmentType,
                locationType: experience.locationType,
                description: experience.description,
                skills: experience.skills,
                industry: experience.industry
              }}
            >
              <Form.Item label="Title" name="title">
                <Input/>
              </Form.Item>

              <Form.Item label="Company" name="company">
                <Input/>
              </Form.Item>

              <Form.Item label="Employment type" name="employmentType">
                <Select>
                  {EMPLOYMENT_TYPE_OPTIONS.map(({ value, label }, index) => (
                    <Select.Option key={index} value={value}>{label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Location" name="location">
                <Input/>
              </Form.Item>

              <Form.Item label="Location type" name="locationType">
                <Select>
                  {LOCATION_TYPE_OPTIONS.map(({ value, label }, index) => (
                    <Select.Option key={index} value={value}>{label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Duration" name="duration">
                <RangePicker picker="month" allowEmpty={[true, true]}/>
              </Form.Item>

              <Form.Item label="Industry" name="industry">
                <Input/>
              </Form.Item>

              <Form.Item label="Description" name="description">
                <Editor id={`${index}`} initialValue={experience.description || ''} onChange={() => null}/>
              </Form.Item>

              <Form.Item label="Skills" name="skills">
                <Select
                  mode="tags"
                  options={SKILL_OPTIONS}
                />
              </Form.Item>
            </Form>
          </Card>
        ))}
      </ModalFullscreen>
    </div>
  )
}
