import { ICampaignModel } from 'src/interfaces'
import { UserUtils } from './user.utils'

export class CampaignUtils {
  static getLogoUrl(campaign?: ICampaignModel) {
    if (campaign?.logoUrl) {
      return campaign.logoUrl
    }
    const company = UserUtils.getFirstCompany(campaign?.author)
    if (company?.logo?.url) {
      return company.logo.url
    }
  }

  static companyName(campaign?: ICampaignModel) {
    const company = UserUtils.getFirstCompany(campaign?.author)
    return company?.name || campaign?.companyName
  }

  static website(campaign?: ICampaignModel) {
    const company = UserUtils.getFirstCompany(campaign?.author)
    return company?.url || company?.urls?.[0] || campaign?.companyWebsite || campaign?.companyWebsiteUrls?.[0]
  }
}
