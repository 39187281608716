import { PlusCircleFilled } from '@ant-design/icons'
import { Avatar, Button, Checkbox, Form, Input, InputNumber, Radio, Select, Space } from 'antd'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { EMPTY, catchError, combineLatest, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, CampaignQuestionApi, ScrapedJobApi } from 'src/api'
import { QuillEditor, VideoPlayer } from 'src/components'
import { ELocationType, ESalaryRate } from 'src/constants/enum'
import { useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { ICampaignModel, ICampaignQuestionModel } from 'src/interfaces'
import { GlobalAction } from 'src/store'
import { CampaignUtils, NotifyUtils, VideoUtils } from 'src/utils'
import Style from './style.module.scss'

const WORK_STYLES_WORDS_OPTIONS = [
  'Leader',
  'Team Player',
  'Relationship Builder',
  'Reflective Thinker',
  'Consistent',
  'Urgent',
  'Detail Oriented',
  'Flexible'
].map((item) => ({ label: item, value: item }))

const MOTIVATORS_WORDS_OPTIONS = [
  'Competitive',
  'Mission-Driven',
  'Learner',
  'Economic',
  'Creative',
  'Individualistic',
  'Altruistic'
].map((item) => ({ label: item, value: item }))

export const ScrapedJobDetail: FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const [campaign, setCampaign] = useState<ICampaignModel>()
  const [questions, setQuestions] = useState<ICampaignQuestionModel[]>([])
  const [videoUrl, setVideoUrl] = useState('')
  const [videoFile, setVideoFile] = useState<File>()
  const [quotes, setQuotes] = useState<Pick<Required<ICampaignModel>, 'disc' | 'motivator'>>({
    disc: {},
    motivator: {}
  })
  const [words, setWords] = useState({
    disc: [] as string[],
    motivator: [] as string[]
  })

  const addQuote = useCallback((type: keyof typeof quotes, word: string) => {
    setQuotes((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [word]: [...(prev[type][word] || []), '']
      }
    }))
  }, [])

  const onQuoteChange = useCallback((type: keyof typeof quotes, word: string, index: number, content: string) => {
    setQuotes((prev) => {
      prev[type][word][index] = content
      return prev
    })
  }, [])

  useEffect(() => {
    if (campaign) {
      setQuotes({
        disc: campaign.disc || {},
        motivator: campaign.motivator || {}
      })
      setWords({
        disc: Object.keys(campaign.disc || {}).reduce((acc, key) => {
          if (key.startsWith('quote')) {
            acc.push(key.replace(/^quote/, ''))
          }
          return acc
        }, [] as string[]),
        // motivator: Object.keys(campaign.motivator || {}).reduce((acc, key) => {
        //   if (key.startsWith('quote')) {
        //     acc.push(key.replace(/^quote/, ''))
        //   }
        //   return acc
        // }, [] as string[])
        motivator: campaign.recommendKey?.split('.') || []
      })
    }
  }, [campaign])

  useEffect(() => {
    if (!videoFile) {
      setVideoUrl(VideoUtils.getVideoSource(campaign?.uploadVideo) || '')
      return
    }

    const url = URL.createObjectURL(videoFile)
    setVideoUrl(url)

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [videoFile, campaign])

  const [form] = Form.useForm<ICampaignModel>()

  useUnsubscribeEffect((unsubscribe$) => {
    GlobalAction.setLoading(true)

    combineLatest(ScrapedJobApi.getCampaign(+id), CampaignQuestionApi.list())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: any) => {
          NotifyUtils.error({ message: error.message })
          return EMPTY
        }),
        finalize(() => {
          GlobalAction.setLoading(false)
        })
      )
      .subscribe(([res, resQuestions]) => {
        const campaign = {
          ...res.data,
          requiredResume: true
        }

        form.setFields([
          {
            name: 'jobTitle',
            value: campaign.jobTitle
          },
          {
            name: 'salaryMin',
            value: campaign.salaryValue?.min
          },
          {
            name: 'salaryMax',
            value: campaign.salaryValue?.max
          },
          {
            name: 'salaryRate',
            value: campaign.salaryRate
          },
          {
            name: 'location',
            value: campaign.location
          },
          {
            name: 'locationType',
            value: campaign.locationType
          },
          {
            name: 'questionIds',
            value: campaign.questionIds?.at(0)
          },
          {
            name: 'requireLinkedInUrl',
            value: campaign.requireLinkedInUrl
          },
          {
            name: 'requireZipCode',
            value: campaign.requireZipCode
          },
          {
            name: 'requiredResume',
            value: campaign.requiredResume
          },
          {
            name: 'briefDescription',
            value: campaign.briefDescription
          }
        ])

        setCampaign(campaign)
        setQuestions(resQuestions.data)
      })
  }, [id])

  const recordResultRef = useRef<HTMLInputElement>(null)

  const handleVideoChange = async (record: File) => {
    setVideoFile(record)
  }

  const onSave = useCallback(async () => {
    if (!campaign) {
      return NotifyUtils.error({ message: 'Job not found' })
    }

    const payload: Partial<ICampaignModel> = {
      ...form.getFieldsValue(),
      recommendKey: words.motivator.join('.'),
      disc: words.disc.reduce((acc, key) => ({
        ...acc,
        [`quote${key}`]: quotes.disc[`quote${key}`] || []
      }), {} as ICampaignModel['disc']),
      motivator: words.motivator.reduce((acc, key) => ({
        ...acc,
        [`quote${key}`]: quotes.motivator[`quote${key}`] || []
      }), {} as ICampaignModel['motivator']),
      questionIds: [form.getFieldValue('questionIds')],
      salaryValue: (form.getFieldValue('salaryMin') || form.getFieldValue('salaryMax'))
        ? {
          min: form.getFieldValue('salaryMin') || 0,
          max: form.getFieldValue('salaryMax') || 0
        }
        : undefined
    }

    GlobalAction.setLoading(true)
    from(CampaignApi.update(campaign?.id, payload))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Update job successfully' })
      })
  }, [campaign, form, words, quotes, unsubscribe$])

  return (
    <div className={Style.container}>
      <div className={Style.actionWrapper}>
        <Button type="default" onClick={history.goBack.bind(history)}>Cancel</Button>
        <Button type="primary" onClick={onSave} disabled={form.getFieldsError().some(item => item.errors?.length)}>Save</Button>
      </div>

      <h1 className={Style.sectionLabel}>Job Detail</h1>

      <Form form={form} layout="vertical" autoComplete="off">
        <div className={Style.detailContainer}>
          <div className={Style.videoContainer}>
            <div className="d-none">
              <input
                type="file"
                ref={recordResultRef}
                accept={['video/*'].join(', ')}
                onChange={(e) => {
                  if (!e.target.files?.[0]) return
                  handleVideoChange(e.target.files?.[0])
                  e.target.value = ''
                }}
              />
            </div>
            {videoUrl
              ? (<VideoPlayer url={videoUrl}/>)
              : (
                <div className={Style.noVideo}>
                  No video
                  {/* <Button
                    onClick={() => recordResultRef.current?.click()}
                    type="primary"
                  >Upload
                  </Button> */}
                </div>
              )}
          </div>
          <div className={Style.jobContainer}>
            <Form.Item
              name="jobTitle"
              label="Job Title"
              rules={[{ required: true }, { type: 'string' }]}
            >
              <Input/>
            </Form.Item>

            <div className="fx flex-row gap-4">
              <Form.Item
                name="salaryMin"
                label="Min salary"
                rules={[{ type: 'number' }]}
              >
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                />
              </Form.Item>

              <Form.Item
                name="salaryMax"
                label="Max salary"
                rules={[{ type: 'number' }]}
              >
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                />
              </Form.Item>

              <Form.Item
                name="salaryRate"
                label="Salary rate"
                rules={[{ type: 'string' }]}
              >
                <Select style={{ width: '200px' }}>
                  <Select.Option value={ESalaryRate.PER_HOUR}>Per Hour</Select.Option>
                  <Select.Option value={ESalaryRate.PER_MONTH}>Per Month</Select.Option>
                  <Select.Option value={ESalaryRate.PER_YEAR}>Per Year</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="fx flex-row gap-4 w-100">
              <Form.Item
                name="locationType"
                label="Workplace type"
                rules={[{ type: 'string' }]}
              >
                <Select style={{ width: '200px' }}>
                  <Select.Option value={ELocationType.ON_SITE}>On Site</Select.Option>
                  <Select.Option value={ELocationType.HYBRID}>Hybrid</Select.Option>
                  <Select.Option value={ELocationType.REMOTE}>Remote</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="location"
                label="Location"
                rules={[{ type: 'string' }]}
                style={{ width: '100%' }}
              >
                <Input/>
              </Form.Item>
            </div>
          </div>
        </div>

        <div className={Style.formBlock}>
          <Form.Item name="questionIds" label="Question" className="mb-0">
            <Radio.Group>
              <Space direction="vertical">
                {questions.map(question => (
                  <Radio key={question.id} value={question.id}>{question.text}</Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className={Style.formBlock}>
          <Form.Item name="requireLinkedInUrl" valuePropName="checked" className="mb-0">
            <Checkbox
              checked={campaign?.requireLinkedInUrl}
              onChange={(e) => form.setFieldValue('requireLinkedInUrl', e.target.checked)}
            >
              Require LinkedIn Url
            </Checkbox>
          </Form.Item>
          <Form.Item name="requireZipCode" valuePropName="checked" className="mb-0">
            <Checkbox
              checked={campaign?.requireZipCode}
              onChange={(e) => form.setFieldValue('requireZipCode', e.target.checked)}
            >
              Require Zip Code
            </Checkbox>
          </Form.Item>
          <Form.Item name="requiredResume" valuePropName="checked" className="mb-0">
            <Checkbox>Require Resume</Checkbox>
          </Form.Item>
        </div>

        <div className={clsx(Style.formBlock, 'fx gap-2')}>
          <div className="fx-1 fx fx-column gap-1">
            <div>Work Styles</div>
            <Form.Item className="mb-0">
              <Select
                style={{ width: '200px' }}
                placeholder="Select a work style"
                allowClear
                options={[WORK_STYLES_WORDS_OPTIONS[0], WORK_STYLES_WORDS_OPTIONS[1]]}
                value={words.disc[0] || undefined}
                onChange={(word) => setWords((prev) => {
                  prev.disc[0] = word
                  return { ...prev }
                })}
              />
              {words.disc[0] && (
                <PlusCircleFilled
                  className="pl-2 pointer"
                  onClick={() => addQuote('disc', `quote${words.disc[0]}`)}
                />
              )}
            </Form.Item>

            {words.disc[0] && quotes.disc[`quote${words.disc[0]}`]?.map((quote, index) => (
              <Input
                key={index}
                value={quote || undefined}
                placeholder="Quote"
                onChange={(e) => onQuoteChange('disc', `quote${words.disc[0]}`, index, e.target.value)}
              />
            ))}

            <Form.Item className="mb-0">
              <Select
                style={{ width: '200px' }}
                placeholder="Select a work style"
                allowClear
                options={[WORK_STYLES_WORDS_OPTIONS[2], WORK_STYLES_WORDS_OPTIONS[3]]}
                value={words.disc[1] || undefined}
                onChange={(word) => setWords((prev) => {
                  prev.disc[1] = word
                  return { ...prev }
                })}
              />
              {words.disc[1] && (
                <PlusCircleFilled
                  className="pl-2 pointer"
                  onClick={() => addQuote('disc', `quote${words.disc[1]}`)}
                />
              )}
            </Form.Item>

            {words.disc[1] && quotes.disc[`quote${words.disc[1]}`]?.map((quote, index) => (
              <Input
                key={index}
                value={quote || undefined}
                placeholder="Quote"
                onChange={(e) => onQuoteChange('disc', `quote${words.disc[1]}`, index, e.target.value)}
              />
            ))}

            <Form.Item className="mb-0">
              <Select
                style={{ width: '200px' }}
                placeholder="Select a work style"
                allowClear
                options={[WORK_STYLES_WORDS_OPTIONS[4], WORK_STYLES_WORDS_OPTIONS[5]]}
                value={words.disc[2] || undefined}
                onChange={(word) => setWords((prev) => {
                  prev.disc[2] = word
                  return { ...prev }
                })}
              />
              {words.disc[2] && (
                <PlusCircleFilled
                  className="pl-2 pointer"
                  onClick={() => addQuote('disc', `quote${words.disc[2]}`)}
                />
              )}
            </Form.Item>

            {words.disc[2] && quotes.disc[`quote${words.disc[2]}`]?.map((quote, index) => (
              <Input
                key={index}
                value={quote || undefined}
                placeholder="Quote"
                onChange={(e) => onQuoteChange('disc', `quote${words.disc[2]}`, index, e.target.value)}
              />
            ))}

            <Form.Item className="mb-0">
              <Select
                style={{ width: '200px' }}
                placeholder="Select a work style"
                allowClear
                options={[WORK_STYLES_WORDS_OPTIONS[6], WORK_STYLES_WORDS_OPTIONS[7]]}
                value={words.disc[3] || undefined}
                onChange={(word) => setWords((prev) => {
                  prev.disc[3] = word
                  return { ...prev }
                })}
              />
              {words.disc[3] && (
                <PlusCircleFilled
                  className="pl-2 pointer"
                  onClick={() => addQuote('disc', `quote${words.disc[3]}`)}
                />
              )}
            </Form.Item>

            {words.disc[3] && quotes.disc[`quote${words.disc[3]}`]?.map((quote, index) => (
              <Input
                key={index}
                value={quote || undefined}
                placeholder="Quote"
                onChange={(e) => onQuoteChange('disc', `quote${words.disc[3]}`, index, e.target.value)}
              />
            ))}

          </div>

          <div className="fx-1 fx fx-column gap-1">
            <div>Motivators</div>
            <Form.Item className="mb-0">
              <Select
                style={{ width: '200px' }}
                placeholder="Rank 1"
                allowClear
                options={MOTIVATORS_WORDS_OPTIONS}
                value={words.motivator[0] || undefined}
                onChange={(word) => setWords((prev) => {
                  prev.motivator[0] = word
                  return { ...prev }
                })}
              />
              {words.motivator[0] && (
                <PlusCircleFilled
                  className="pl-2 pointer"
                  onClick={() => addQuote('motivator', `quote${words.motivator[0]}`)}
                />
              )}
            </Form.Item>

            {words.motivator[0] && quotes.motivator[`quote${words.motivator[0]}`]?.map((quote, index) => (
              <Input
                key={index}
                value={quote || undefined}
                placeholder="Quote"
                onChange={(e) => onQuoteChange('motivator', `quote${words.motivator[0]}`, index, e.target.value)}
              />
            ))}

            <Form.Item className="mb-0">
              <Select
                style={{ width: '200px' }}
                placeholder="Rank 2"
                allowClear
                options={MOTIVATORS_WORDS_OPTIONS}
                value={words.motivator[1] || undefined}
                onChange={(word) => setWords((prev) => {
                  prev.motivator[1] = word
                  return { ...prev }
                })}
              />
              {words.motivator[1] && (
                <PlusCircleFilled
                  className="pl-2 pointer"
                  onClick={() => addQuote('motivator', `quote${words.motivator[1]}`)}
                />
              )}
            </Form.Item>

            {words.motivator[1] && quotes.motivator[`quote${words.motivator[1]}`]?.map((quote, index) => (
              <Input
                key={index}
                value={quote || undefined}
                placeholder="Quote"
                onChange={(e) => onQuoteChange('motivator', `quote${words.motivator[1]}`, index, e.target.value)}
              />
            ))}

            <Form.Item className="mb-0">
              <Select
                style={{ width: '200px' }}
                placeholder="Rank 3"
                allowClear
                options={MOTIVATORS_WORDS_OPTIONS}
                value={words.motivator[2] || undefined}
                onChange={(word) => setWords((prev) => {
                  prev.motivator[2] = word
                  return { ...prev }
                })}
              />
              {words.motivator[2] && (
                <PlusCircleFilled
                  className="pl-2 pointer"
                  onClick={() => addQuote('motivator', `quote${words.motivator[2]}`)}
                />
              )}
            </Form.Item>

            {words.motivator[2] && quotes.motivator[`quote${words.motivator[2]}`]?.map((quote, index) => (
              <Input
                key={index}
                value={quote || undefined}
                placeholder="Quote"
                onChange={(e) => onQuoteChange('motivator', `quote${words.motivator[2]}`, index, e.target.value)}
              />
            ))}
          </div>
        </div>

        <div className={Style.formBlock}>
          <Form.Item name="briefDescription">
            <QuillEditor/>
          </Form.Item>
        </div>
      </Form>

      <h1 className={Style.sectionLabel}>Company Information</h1>

      <div className={Style.companyContainer}>
        <div className="fx flex-row fx-ai-center gap-4">
          <Avatar
            size={80}
            shape="square"
            src={CampaignUtils.getLogoUrl(campaign)}
          />
          <p>{CampaignUtils.companyName(campaign)}</p>
          <p>{CampaignUtils.website(campaign)}</p>
        </div>
      </div>
    </div>
  )
}
