import { Pagination, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil, tap } from 'rxjs'
import { CompanyUserApi } from 'src/api'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { ICampaignModel, ICompanyUserModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'Unverified HM'

export const Unverified: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const _paginationService = useMemo(() => new PaginationService<ICompanyUserModel & { campaign?: ICampaignModel }>(CompanyUserApi), [])
  const _loadingPaging = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const [_loading, setLoading] = useState(false)
  const loading = useMemo(() => _loading || _loadingPaging, [_loading, _loadingPaging])

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging({
      ...params,
      order: 'id-DESC'
    }),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter/** , setFilter */] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.CAMPAIGN,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const onApprove = useCallback((doc: ICompanyUserModel) => {
    setLoading(true)
    from(CompanyUserApi.approve(doc.id))
      .pipe(
        tap(() => {
          doc.isVerified = true
        }),
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Hiring manager has been verified!' })
      })
  }, [unsubscribe$])

  return (
    <section className="fx fx-column fx-extend">
      {/* <div className="fx fx-extend gap-2 mb-2">
        <Input
          allowClear
          placeholder="Search by content..."
          style={{ flex: '0 0 20vw' }}
          readOnly={loading}
          value={filter.keyword as string}
          onChange={(e) => {
            e.persist()
            setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))
          }}
        />
      </div> */}

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          loading,
          onApprove
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
