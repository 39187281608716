import { IUserModel } from 'src/interfaces'

export class UserUtils {
  static getFirstName(user?: Partial<IUserModel>) {
    if (!user) {
      return ''
    }

    return user.firstName || user.fullName?.split(' ')[0] || ''
  }

  static getFullName(user?: Partial<IUserModel>) {
    if (!user) {
      return ''
    }

    return user.fullName || [user.firstName, user.lastName].filter(Boolean).join(' ')
  }

  static formatPhoneUSA(phoneNo?: string) {
    if (!phoneNo) {
      return ''
    }

    return phoneNo.replace(/^(.*)(\d{3})(\d{3})(\d{4})$/, '+$1 ($2)-$3-$4')
  }

  static getFirstCompany = (user?: Partial<IUserModel>) => {
    if (!user?.companies?.length) {
      return null
    }
    return user.companies[0]
  }
}
