import { Avatar } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'
import { ICampaignModel } from 'src/interfaces'
import { CampaignUtils } from 'src/utils'
import { CampaignVideo } from './videos'

interface IProps {
  campaign?: ICampaignModel
  className?: string
}

export const CompanyDetail: FC<IProps> = ({ campaign, className }) => {
  return (
    <div className={clsx('fx-1 fx-column gap-2', className)}>
      <h2><strong>Company Detail</strong></h2>

      <div className={clsx('fx fx-ai-center gap-4 fx-jc-space-between', className)}>
        <div className="fx-1 fx fx-ai-center gap-4">
          <Avatar size={56} src={CampaignUtils.getLogoUrl(campaign)}>
            {CampaignUtils.companyName(campaign)}
          </Avatar>

          <div className="fx-1 fx fx-column">
            <div className="fx gap-2 fx-ai-center">
              <strong>Company Name:</strong>
              {CampaignUtils.companyName(campaign)}
            </div>

            <div className="fx gap-2">
              <strong>Company Website:</strong>
              <div className="fx-1 fx fx-column twoline-text">
                {CampaignUtils.website(campaign)}
              </div>
            </div>
          </div>

        </div>
      </div>

      {campaign?.companyVideo && (
        <>
          <strong>Video</strong>
          <CampaignVideo campaign={campaign} isCompany/>
        </>
      )}
    </div>
  )
}
