import axios, { AxiosRequestConfig } from 'axios'
import { ICampaignModel, ICompanyUserModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class CompanyUserApi {
  static readonly _prefix = '/company-user'

  static index(
    params: IPaginationQuery = {},
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<ICompanyUserModel & { campaign?: ICampaignModel }> {
    return axios.get(`${CompanyUserApi._prefix}`, {
      ...config,
      params: params || config?.params
    })
  }

  static approve(
    id: number | string,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axios.put(`${CompanyUserApi._prefix}/${id}/approve`, null, config)
  }
}
